import React from "react";
import { Helmet } from "react-helmet";
import Preloader from "./src/components/Preloader";
import Page from "./src/components/Page";
import PageTransition from "./src/components/pageTransition";
import OG from "./src/images/og.jpg";

import "./src/fonts/Antonio-Bold.woff";
import "./src/fonts/Antonio-Bold.woff2";
import "./src/fonts/SpaceGrotesk-Medium.woff";
import "./src/fonts/SpaceGrotesk-Medium.woff2";
import { GlobalStateProvider } from "./src/context/Global";

export const shouldUpdateScroll = () => false;

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <Helmet>
        <title>End Product - A social-first sports content agency</title>
        <meta
          name="description"
          content="A Social-first sports creative agency"
        />
        <meta
          property="og:title"
          content="End Product - A social-first sports content agency"
        />
        <meta
          property="og:description"
          content="A Social-first sports creative agency"
        />
        <meta property="og:image" content={OG} />
      </Helmet>
      <GlobalStateProvider>
        <Preloader />

        <PageTransition location={props.location}>
          <Page {...props}>{element}</Page>
        </PageTransition>
      </GlobalStateProvider>
    </>
  );
};
