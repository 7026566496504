import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Wrapper from "./Wrapper";
import { Link } from "gatsby";
import Logo from "../images/logo.svg";
import Rollover from "./Rollover";
import gsap from "gsap";
import { device } from "../vars/media";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Rollover2 from "./Rollover2";
import MobileNav from "./MobileNav";

const Header = () => {
  const ref = useRef(null);
  useEffect(() => {
    gsap.to(ref.current, {
      autoAlpha: 1,
      duration: 0.7,
      delay: 0.2,
    });
  }, []);

  return (
    <StyledHeader ref={ref} id="header">
      <Wrapper>
        <div className="headerLinks">
          <AnchorLink to="/#about">
            <Rollover2>About</Rollover2>
          </AnchorLink>
          <AnchorLink to="/#work">
            <Rollover2>Our end product</Rollover2>
          </AnchorLink>
          <AnchorLink to="/#what-we-do">
            <Rollover2>Why us</Rollover2>
          </AnchorLink>
          <AnchorLink to="/#services">
            <Rollover2>Services</Rollover2>
          </AnchorLink>
        </div>

        <Link to="/" className="logo">
          <Logo />
        </Link>

        <MobileNav />

        <div className="headerLinks">
          <a
            href="https://endproductsubscriptions.framer.website/"
            target="_blank"
          >
            <Rollover2>Subscriptions</Rollover2>
          </a>

          <a href="mailto:hello@endproduct.agency">
            <Rollover2>Let's talk</Rollover2>
          </a>
        </div>
      </Wrapper>
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled.header`
  opacity: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  .wrapper {
    display: flex;
    justify-content: space-between;
  }

  .headerLinks {
    display: none;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.9rem;
    margin: 0 -0.5rem;

    a {
      padding: 0 0.5rem;
      cursor: pointer;
    }
  }

  a.logo {
    display: flex;
    justify-content: center;

    svg {
      width: 80px;
    }

    ${device.laptop} {
      svg {
        width: 90px;
      }

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  ${device.laptop} {
    height: 103px;

    .wrapper {
      width: 100%;
    }

    .headerLinks {
      display: flex;
      align-items: center;
    }
  }

  ${device.laptopL} {
    padding-top: 2rem;

    .logo {
      padding-top: 2rem;
    }

    .headerLinks {
      font-size: 0.96rem;
      margin: 0 -1rem;

      a {
        padding: 0 0.6rem;
      }
    }
  }
`;
