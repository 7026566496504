import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { quart } from "../vars/ease";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { device } from "../vars/media";

const MobileNav = () => {
  const tl = useRef();
  const ref = useRef(null);
  const menu = useRef(null);
  const [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
    tl.current = gsap.timeline({
      paused: true,
      onStart: () => gsap.set(menu.current, { pointerEvents: "all" }),
      onReverseComplete: () =>
        gsap.set(menu.current, { pointerEvents: "none" }),
    });

    tl.current
      .to(
        ref.current.children[0],
        {
          y: 2.5,
          rotate: 45,
          ease: quart,
          backgroundColor: "#000",
          duration: 0.6,
        },
        0
      )
      .to(
        ref.current.children[1],
        {
          y: 0,
          rotate: -45,
          ease: quart,
          backgroundColor: "#000",
          duration: 0.6,
        },
        0
      )

      .to(
        menu.current.querySelector(".menu__bg"),
        {
          scaleX: 1,
          ease: quart,
          duration: 0.6,
        },
        0
      )

      .from(
        menu.current.querySelectorAll(".menu__item"),
        {
          y: 5,
          autoAlpha: 0,
          stagger: 0.1,
          duration: 0.3,
        },
        0.35
      );
  }, []);

  useEffect(() => {
    navOpen ? tl.current.play() : tl.current.reverse();
  }, [navOpen]);

  return (
    <>
      <Hamburger onClick={() => setNavOpen(!navOpen)} ref={ref}>
        <div className="line"></div>
        <div className="line"></div>
      </Hamburger>

      <Menu ref={menu}>
        <div className="menu__bg"></div>
        <div className="menu__inner">
          <span className="menu__item" onClick={() => setNavOpen(!navOpen)}>
            <AnchorLink className="h" to="/#about">
              About
            </AnchorLink>
          </span>
          <span className="menu__item" onClick={() => setNavOpen(!navOpen)}>
            <AnchorLink className="h" to="/#work">
              Our end product
            </AnchorLink>
          </span>
          <span className="menu__item" onClick={() => setNavOpen(!navOpen)}>
            <AnchorLink className="h" to="/#what-we-do">
              Why us
            </AnchorLink>
          </span>
          <span className="menu__item" onClick={() => setNavOpen(!navOpen)}>
            <AnchorLink className="h" to="/#services">
              Services
            </AnchorLink>
          </span>
          <span className="menu__item" onClick={() => setNavOpen(!navOpen)}>
            <a href="mailto:hello@endproduct.agency" className="h">
              Let's talk
            </a>
          </span>

          <span className="menu__item" onClick={() => setNavOpen(!navOpen)}>
            <a
              href="https://endproductsubscriptions.framer.website/"
              target="_blank"
              className="h"
            >
              Subscriptions
            </a>
          </span>
        </div>
      </Menu>
    </>
  );
};

export default MobileNav;

const Hamburger = styled.div`
  width: 35px;
  height: 35px;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 2;

  .line {
    display: block;
    height: 2px;
    width: 100%;
    background: #fff;
    transform: translateY(-5px);

    &:nth-of-type(2) {
      transform: translateY(5px);
    }
  }

  ${device.laptop} {
    display: none;
  }
`;

const Menu = styled.div`
  will-change: transform;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;

  .menu__bg {
    height: 100%;
    width: 100%;
    background: #fff;
    transform: scaleX(0);
    transform-origin: right;
  }

  .menu__item {
    margin: 0.5rem 0;
  }

  .menu__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #000;
    display: flex;
    flex-direction: column;
    text-align: center;

    .h {
      font-size: 2rem;
    }
  }

  ${device.laptop} {
    display: none;
  }
`;
