import React from "react";
import styled from "styled-components";
import Marquee from "react-fast-marquee";
import { device } from "../vars/media";
import * as c from "../vars/colours";

const MarqueeText = ({ theme, children, direction }) => {
  const t = theme || "dark";

  //layout shifting issues
  return (
    <StyledMarquee className={`${t} marqueeText`}>
      <Marquee gradient={false} speed={100} direction={direction}>
        <div className="h">
          <span>{children}</span>
          {/* <span className="spacer"></span> */}
          <span>{children}</span>
        </div>
      </Marquee>
    </StyledMarquee>
  );
};

export default MarqueeText;

const StyledMarquee = styled.div`
  color: #fff;
  white-space: nowrap;
  font-size: 20.5vw;
  padding: 1rem 0;
  border-bottom: 1px solid ${c.white};
  margin-bottom: 3.75rem;

  .marquee {
    min-width: auto;
  }

  &.light {
    color: #000;
    border-color: #00000099;
  }

  .h {
    line-height: normal;
    /* margin: 0 1rem; */
  }

  span {
    margin: 0 1rem;
    display: inline-block;
  }

  ${device.tablet} {
    font-size: 8rem;

    .h {
      margin-bottom: 1rem;
    }
  }

  ${device.laptop} {
    font-size: 10rem;
    height: 26vw;

    span {
      margin: 0 2rem;
    }
  }

  ${device.laptopL} {
    font-size: 12.5rem;
    height: 21rem;

    span {
      margin: 0 2rem;
    }

    .h {
      margin-bottom: 2rem;
    }
  }

  ${device.desktop} {
    /* font-size: 15rem; */
    /* height: 24rem; */

    span {
      margin: 0 3rem;
    }

    .h {
      margin-bottom: 3rem;
    }
  }
`;
