import React, { createContext, useState } from "react";

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [preloaded, setPreloaded] = useState(false);
  const [transitioned, setTransitioned] = useState(true);

  return (
    <GlobalStateContext.Provider
      value={{ preloaded, setPreloaded, transitioned, setTransitioned }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
