import React from "react";
import styled from "styled-components";
import { device } from "../vars/media";

const Wrapper = ({ children, inner }) => {
  return (
    <WrapperComponent className={inner ? "wrapper wrapper__inner" : "wrapper"}>
      {children}
    </WrapperComponent>
  );
};

export default Wrapper;

const WrapperComponent = styled.div`
  padding: 1.475rem;
  width: 100%;
  margin: 0 auto;

  ${device.laptop} {
    padding: 0 3rem;
  }

  ${device.desktop} {
    padding: 0 7rem;
  }

  &.wrapper__inner {
    padding: 0 1.25rem;
    max-width: 1440px;

    ${device.tablet} {
      padding: 0 2.5rem;
    }

    ${device.laptop} {
      padding: 0 3rem;
    }

    ${device.laptopL} {
      padding: 0 6rem;
    }

    ${device.desktop} {
      padding: 0;
    }
  }
`;
