import React from "react";
import { FaTiktok, FaYoutube, FaLinkedin, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import styled from "styled-components";
import MarqueeText from "./MarqueeText";
import Logo from "../images/logo.svg";
import * as c from "../vars/colours";
import Wrapper from "./Wrapper";
import { device } from "../vars/media";
import Rollover3 from "./Rollover3";

const Footer = () => {
  return (
    <StyledFooter className="footer">
      <MarqueeText theme="light">Let's Talk!</MarqueeText>

      <a href="mailto:hello@endproduct.agency" className="caps h em">
        <Wrapper inner="true">
          <Rollover3>Hello@endproduct.agency</Rollover3>
        </Wrapper>
      </a>

      <Wrapper inner="true">
        <div className="footer__bottom">
          <div className="logo-left">
            <Logo></Logo>
            <div className="social">
              <a
                href="https://www.instagram.com/weareendproduct"
                target="_blank"
              >
                <FaInstagram />
              </a>
              <a href="https://twitter.com/weareendproduct" target="_blank">
                <FaXTwitter />
              </a>
              <a
                href="https://www.tiktok.com/@weareendproduct?lang=en"
                target="_blank"
              >
                <FaTiktok />
              </a>
              <a
                href="https://uk.linkedin.com/company/end-product"
                target="_blank"
              >
                <FaLinkedin />
              </a>
              <a
                href="https://www.youtube.com/channel/UCVDP7_NfopN6-7xNSJM23RA"
                target="_blank"
              >
                <FaYoutube />
              </a>
            </div>
          </div>

          <span className="legal caps h">
            © {new Date().getFullYear()} end product ltd. All rights reserved.
            Made by{" "}
            <a href="mailto:hello@lukedagnall.com" className="ld">
              {" "}
              LD
            </a>
          </span>
        </div>
      </Wrapper>
    </StyledFooter>
  );
};

export default Footer;

const StyledFooter = styled.footer`
  background-color: ${c.white};

  a {
    cursor: pointer;
  }

  .ld {
    margin-left: 0.2rem;
    display: inline-block;

    &:hover {
      opacity: 0.5;
    }
  }

  .logo-left {
    display: flex;
    align-items: center;
    flex-direction: column;

    ${device.tablet} {
      flex-direction: row;
    }
  }

  .marqueeText {
    margin-bottom: 3.75rem;
    border-top: 1px solid #00000099;
  }

  .em {
    font-size: 1.6rem;
    display: block;
    text-align: center;
    margin: 3.75rem 0;
  }

  .footer__bottom {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-top: 1px solid #00000099;

    svg:not(.social svg) {
      margin: 0.4rem 0;
      width: 4rem;

      path {
        fill: #000;
      }
    }

    .social {
      margin: 1rem 0;

      svg {
        width: 1.2rem;
        height: 1.2rem;
        margin: 0 0.6rem;
        transition: opacity 0.2s ease-in-out;

        &:hover {
          opacity: 0.6;
        }
      }

      ${device.tablet} {
        margin: 0;
        margin-left: 1.1rem;
        svg {
          width: 1.1rem;
          height: 1.3rem;
          margin: 0 0.4rem;
        }
      }
    }

    .legal {
      display: flex;
      align-items: center;
      letter-spacing: -0.7px;
      font-size: 0.87rem;

      svg {
        margin: 0 0.3rem;
      }
    }
  }

  ${device.tablet} {
    .em {
      font-size: 2.2rem;
      margin: 5rem 0;
    }
    .footer__bottom {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  ${device.laptop} {
    .footer__bottom {
      .legal {
        font-size: 0.9rem;
      }
    }

    .em {
      font-size: 2.6rem;
      margin: 6rem 0;
    }
  }

  ${device.laptopL} {
    .footer__bottom {
      svg:not(.social svg) {
        width: 5.5rem;
        margin: 0.6rem 0;
      }

      .legal {
        font-size: 1rem;
      }
    }
    .em {
      font-size: 3rem;
      margin: 7rem 0;
    }
  }
`;
