import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import styled from "styled-components";
import { quart } from "../vars/ease";

const Rollover2 = ({ children }) => {
  const ref = useRef();
  const tl = useRef();

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true });
    tl.current.addPause("in");

    tl.current.to(
      ref.current.querySelectorAll("div"),
      {
        yPercent: -100,
        ease: quart,
        color: "black",
        // fontWeight: "700",
      },
      0
    );

    tl.current.to(
      ref.current.querySelector("span"),
      {
        scaleY: 1,
        ease: quart,
        transformOrigin: "bottom",
      },
      0,
      "in"
    );

    tl.current
      .to(
        ref.current.querySelector("span"),
        {
          scaleY: 0,
          transformOrigin: "top",
          ease: quart,
        },
        "out"
      )

      .to(
        ref.current.querySelectorAll("div"),
        {
          color: "white",
          ease: quart,
          // fontWeight: "300",
        },
        "out"
      );

    ref.current.addEventListener("mouseenter", () => {
      tl.current.tweenFromTo(0, "out");
    });

    ref.current.addEventListener("mouseleave", () => {
      tl.current.play();
    });
  }, []);

  return (
    <Rlo className="rlo" ref={ref}>
      <div>{children}</div>
      <div>{children}</div>
      <span></span>
    </Rlo>
  );
};

export default Rollover2;

const Rlo = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 4px;

  span {
    display: block;
    position: absolute;
    width: 100%;
    background: yellow;
    height: 100%;
    left: -2px;
    transform: scaleY(0);
    transform-origin: bottom;
  }

  div:nth-of-type(1) {
    transform: translateY(100%);
    z-index: 1;
  }

  div:nth-of-type(2) {
    /* display: none; */
    position: absolute;
    /* transform: translateY(100%); */
  }
`;
