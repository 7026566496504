import React, { useRef, useContext, useLayoutEffect } from "react";
import { GlobalStateContext } from "../context/Global";
import Header from "./Header";
import Footer from "./Footer";
import "../style.css";
import gsap from "gsap";
import Anchor from "./Anchor";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const Page = ({ children }) => {
  const { preloaded, transitioned } = useContext(GlobalStateContext);
  const wrapper = useRef();
  const content = useRef();

  gsap.config({ nullTargetWarn: false });

  useLayoutEffect(() => {
    ScrollSmoother.create({
      wrapper: wrapper.current,
      content: content.current,
      smooth: 1.4,
    });
  }, [transitioned, preloaded]);

  return preloaded && transitioned ? (
    <main>
      <div ref={wrapper} className="inner">
        <div ref={content}>
          <Header />
          {children}
          <Footer />
        </div>
      </div>
      <Anchor />
    </main>
  ) : null;
};

export default Page;
