import { AnchorLink } from "gatsby-plugin-anchor-links";
import { RxTriangleUp } from "react-icons/rx";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { circ } from "../vars/ease";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Anchor = () => {
  const tl = useRef(null);
  const ref = useRef(null);

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true });

    tl.current.to(ref.current, {
      x: 0,
      duration: 0.5,
      ease: circ,
    });

    ScrollTrigger.create({
      start: "20%",
      endTrigger: ".footer",
      end: "top 100%",
      onEnter: () => tl.current.play(),
      onLeaveBack: () => tl.current.reverse(),
      onEnterBack: () => tl.current.play(),
      onLeave: () => tl.current.reverse(),
    });
  }, []);

  return (
    <S2T ref={ref}>
      <AnchorLink to="#header">
        <RxTriangleUp />
      </AnchorLink>
    </S2T>
  );
};

export default Anchor;

const S2T = styled.div`
  will-change: transform;
  transform: translateX(150%);
  display: flex;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  background: #000;
  border-radius: 60px;
  height: 60px;
  width: 60px;
  z-index: 10;
  cursor: pointer;
  border: 1px solid #3b3b3b;

  &:hover {
    background: #fff;

    svg path {
      fill: #000;
    }
  }

  a {
    height: 100%;
    width: 100%;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    pointer-events: none;
    /* margin-top: 0.3rem; */
    height: 25px;
    width: 25px;
  }
  svg path {
    fill: #fff;
  }
`;
