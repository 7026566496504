import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import styled from "styled-components";
import { quart } from "../vars/ease";

const Rollover = ({ children }) => {
  const ref = useRef();
  const tl = useRef();

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true });
    tl.current.addPause("in");

    tl.current.to(
      ref.current.querySelectorAll("div"),
      {
        yPercent: -100,
        ease: quart,
        color: "yellow",
      },
      "in"
    );

    tl.current
      .to(
        ref.current.querySelector("span"),
        {
          scaleY: 0,
          transformOrigin: "top",
          ease: quart,
        },
        "out"
      )

      .to(
        ref.current.querySelectorAll("div"),
        {
          yPercent: 0,
          ease: quart,
          color: "white",
        },
        "out"
      );

    ref.current.addEventListener("mouseenter", () => {
      tl.current.tweenFromTo(0, "out");
    });

    ref.current.addEventListener("mouseleave", () => {
      tl.current.play();
    });
  }, []);

  return (
    <Rlo className="rlo" ref={ref}>
      <div>{children}</div>
      <div>{children}</div>
    </Rlo>
  );
};

export default Rollover;

const Rlo = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  span {
    display: block;
    position: absolute;
    width: 105%;
    background: #fff;
    height: 100%;
    transform: scaleY(0);
    left: -2.5%;
    transform-origin: bottom;
    mix-blend-mode: difference;
  }

  div:nth-of-type(1) {
    transform: translateY(100%);
  }

  div:nth-of-type(2) {
    /* display: none; */
    position: absolute;
    /* transform: translateY(100%); */
  }
`;
