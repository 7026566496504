import React, { useState, useContext, useRef } from "react";
import { TransitionGroup, Transition } from "react-transition-group";
import { gsap } from "gsap";
import ScrollSmoother from "gsap/ScrollSmoother";
import ScrollTrigger from "gsap/ScrollTrigger";
import styled from "styled-components";
import { quart } from "../vars/ease";
import { GlobalStateContext } from "../context/Global";
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

const PageTransition = ({ children, location }) => {
  const { transitioned, setTransitioned } = useContext(GlobalStateContext);

  const wipe = useRef(null);

  return (
    <TransitionGroup>
      <Wipe ref={wipe} />
      <Transition
        key={location.pathname}
        timeout={900}
        unmountOnExit={false}
        onExit={(node) => {
          gsap.to(node, { opacity: 0, duration: 0.6 });
          gsap.set("main:nth-of-type(1)", {
            position: "absolute",
            width: "100%",
            display: "none",
            height: 0,
          });
          gsap.to(wipe.current, {
            scaleX: 1,
            ease: quart,
            duration: 0.9,
          });
        }}
        onEnter={(node) => {
          gsap.set(node, { opacity: 0 });
        }}
        onEntered={(node) => {
          setTransitioned(false);
          const currentScroll = ScrollSmoother.get();
          currentScroll.paused(true);
          currentScroll.scrollTo(0);
          window.scrollTo(0, 0);
          gsap.set(node, { opacity: 1 });

          console.log(node);

          gsap.to(wipe.current, {
            scaleX: 0,
            transformOrigin: "right",
            ease: quart,
            duration: 0.9,
            onStart: () => {
              setTransitioned(true);
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 100);
            },
            onComplete: () => {
              currentScroll.paused(false);
              gsap.set(wipe.current, { clearProps: "all" });
            },
          });
        }}
      >
        {children}
      </Transition>
    </TransitionGroup>
  );
};

export default PageTransition;

const Wipe = styled.div`
  will-change: transform;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: scaleX(0);
  transform-origin: left;
  height: 100vh;
  z-index: 99;
`;
