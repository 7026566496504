import React, { useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import gsap from "gsap";
import Lottie from "lottie-react";
import Load from "./load2.json";
import { quart } from "../vars/ease";
import { GlobalStateContext } from "../context/Global";
import { device } from "../vars/media";

const Preloader = () => {
  const { preloaded, setPreloaded, setTransitioned } =
    useContext(GlobalStateContext);

  const loadAnim = useRef(null);
  const swipe = useRef(null);
  const tl = useRef(null);
  const pre = useRef(null);

  useEffect(() => {
    gsap.set("body", { opacity: 1, ease: "none" });
    tl.current = gsap.timeline({ paused: true });
    loadAnim.current.setSpeed(3);
    loadAnim.current.play();

    tl.current
      .to(
        swipe.current,
        {
          scaleY: 1,
          duration: 1.1,
          ease: quart,
        },
        0
      )

      .to(
        ".preWrap",
        {
          y: 10,
          autoAlpha: 0,
          duration: 0.2,
          onStart: () => {
            setTimeout(() => {
              setPreloaded(true);
            }, 100);
          },
        },
        1.1
      )

      .to(
        pre.current,
        {
          scaleY: 0,
          duration: 0.7,
          ease: quart,
        },
        1.3
      );
  }, []);

  const handleComplete = () => {
    tl.current.timeScale(1.8).play();
  };

  return (
    <Preload ref={pre}>
      <Lottie
        className="preWrap"
        animationData={Load}
        loop={false}
        autoPlay={false}
        onComplete={() => handleComplete()}
        ref={loadAnim}
        lottieRef={loadAnim}
      />
      <div className="swipe" ref={swipe}></div>
    </Preload>
  );
};

export default Preloader;

const Preload = styled.div`
  will-change: filter;
  will-change: transform;
  pointer-events: none;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  pointer-events: none;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform-origin: top;

  svg {
    will-change: transform;
  }

  .swipe {
    will-change: filter;
    will-change: transform;
    width: 100%;
    height: 100%;
    transform-origin: bottom;
    transform: scaleY(0);
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .preWrap {
    will-change: filter;
    will-change: transform;
    z-index: 5;
    max-width: 800px;
    mix-blend-mode: difference;
    svg {
      will-change: filter;
      will-change: transform;
      width: 430px !important;
      height: auto;

      ${device.laptop} {
        width: 630px !important;
      }
    }
  }
`;
